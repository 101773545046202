(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['skins.viewer.bgImage.bgImageSkin'] = {
  "react": [],
  "css": {}
}
 skins['skins.viewer.bgMedia.bgMediaSkin'] = {
  "react": [],
  "css": {}
}
 skins['skins.viewer.bgOverlay.bgOverlaySkin'] = {
  "react": [
    [
      "div",
      "overlay",
      [],
      {}
    ]
  ]
}
 skins['skins.viewer.bgVideo.html5VideoSkin'] = {
  "react": [
    [
      "video",
      "video",
      [],
      {
        "role": "presentation"
      }
    ],
    [
      "div",
      "poster",
      [],
      {}
    ]
  ],
  "exports": {
    "poster": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "css": {}
}
 skins['skins.viewer.bgVideo.iframeVideoSkin'] = {
  "react": [
    [
      "div",
      "video",
      [],
      {}
    ],
    [
      "div",
      "poster",
      [],
      {}
    ]
  ],
  "exports": {
    "poster": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "css": {}
}
 skins['skins.viewer.bgVideo.webglMediaSkin'] = {
  "react": [
    [
      "canvas",
      "canvas",
      [],
      {}
    ]
  ],
  "css": {}
}

        return skins;
    }));